<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">Jurnal Kembali</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-credit"
                      >{{ labelPath }} Usaha
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Jurnal Kembali
                  </li>
                </ol>
              </nav>
            </div>

            <div>
              <button class="btn btn-save" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card info-detail">
            <div class="row">
              <div class="col-12">
                <div class="title">Detail Jurnal Kembali</div>
                <div class="sub-title">Edit informasi yang diperlukan</div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Tanggal</label>
                  <Datepicker
                    :autoApply="true"
                    :closeOnScroll="true"
                    v-model="formData.tanggal"
                    placeholder="Masukkan Tanggal"
                    :format="format"
                  ></Datepicker>
                  <div
                    class="form-error"
                    v-if="formError && formError.jatuh_tempo"
                  >
                    {{ formError.jatuh_tempo }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Kas & Bank :</label>
                  <Select2
                    v-model="formData.id_client"
                    :options="OptionKasBank"
                    :class="{ 'is-invalid': formError && formError.id_client }"
                    placeholder="Pilih Kas & Bank  "
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_client"
                  >
                    {{ formError.id_client }}
                  </div>
                </div>
                <div class="form-group" v-if="formData.id">
                  <label>Umur Piutang</label>
                  <div class="input-group">
                    <input
                      type="text"
                      disabled="true"
                      class="form-control text-right"
                      v-model="umur"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">Hari</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label>Nomor Referensi :</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Masukkan Nomor Referensi"
                    @keyup="formChange('nama')"
                    v-model="formData.nama"
                    :class="{ 'is-invalid': formError && formError.nama }"
                  />
                  <div class="form-error" v-if="formError && formError.nama">
                    {{ formError.nama }}
                  </div>
                </div>

                <div class="form-group">
                  <label class="">Nominal :</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">Rp</div>
                    </div>
                    <input
                      type="text"
                      v-model="nominal"
                      class="form-control text-right"
                      placeholder="Masukkan Nominal"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!isEdit">
              <div class="row">
                <div class="col-12">
                  <div class="title">Rincian Jurnal Kembali</div>
                  <div class="sub-title">
                    Rincian yang ada pada Jurnal Kembali
                  </div>
                </div>
              </div>
              <hr />
              <div class="d-flex justify-content-between">
                <div class="search">
                  <div class="form-group" style="width: 400px">
                    <Select2
                      v-model.lazy="AccountEstimated"
                      :options="OptionAccountEstimated"
                      placeholder="Pilih akun perkiraan..."
                      :settings="{
                        templateResult: formatState,
                      }"
                      @change="formChange('')"
                      @select="mySelectAccountEstimated($event)"
                    />
                  </div>
                </div>
                <div class="card-total">
                  <div class="total-debit d-flex flex-column">
                    <span>Nilai</span>
                    <span class="ml-auto">Rp </span>
                  </div>
                </div>
              </div>

              <div v-if="formError && formError.nilai">
                <div
                  class="form-error"
                  :style="{ marginBottom: '5px', fontSize: '15px' }"
                >
                  Masukan Terlebih dahulu rincian piutang
                </div>
              </div>

              <div class="table-responsive mt-4">
                <table
                  class="table table-bordered table-striped"
                  id="tableDepartment"
                >
                  <thead>
                    <tr>
                      <th style="width: 15%">Akun</th>
                      <th style="width: 20%">Nama Akun</th>
                      <th style="width: 20%">Nilai</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in formDetail"
                      :key="index"
                      @click="
                        editDetail(
                          index,
                          value.akun,
                          value.nama,
                          value.nilai,
                          $event
                        )
                      "
                      :style="{ cursor: 'pointer' }"
                    >
                      <td>{{ value.akun }}</td>
                      <td>{{ value.nama }}</td>
                      <td
                        class="text-right position-relative"
                        style="padding-right: 40px"
                      >
                        {{ formatMoney(value.nilai) }}
                        <img
                          src="/img/avatar/icon-delete.svg"
                          alt=""
                          class="btn-add-row"
                          style="
                            position: absolute;
                            right: 10px;
                            width: 20px;
                            top: 50%;
                            transform: translateY(-50%);
                            cursor: pointer;
                          "
                        />
                      </td>
                    </tr>
                    <tr v-if="formDetail.length === 0">
                      <td colspan="3" class="text-center">
                        No data available in table
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
  <InvoiceComponent
    :show="showModalInvoice"
    :noInvoice="NomorInvoice"
    :type="'piutang'"
    @reload="getData()"
    @hide="hideInvoice"
  />
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import InvoiceComponent from "../components/invoiceComponents.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
// import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

// import $ from "jquery";
// import { get_ListDivisi } from "../../../actions/master";
// import { get_AkunList } from "../../../actions/akun_perkiraan";
// import { checkRules, cksClient, showAlert } from "../../../helper";
// import moment from "moment";
import "moment/locale/id";
import { VMoney } from "v-money";
import { maska } from "maska";
// import { get_ListClient } from "../../../actions/client";
// import { get_ProjectList } from "../../../actions/project";
// import { get_PiutangDetail, post_PiutangSave } from "../../../actions/piutang";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    InvoiceComponent,
    Select2,
    Datepicker,
    // TableLite
  },
  directives: {
    money: VMoney,
    maska,
  },

  data() {
    return {
      validated: 1,
      btnHide: false,
      statusPayment: ["Paid", "Unpaid"], // or [{id: key, text: value}, {id: key, text: value}]
      OptionKasBank: [],
      optionDepartemen: [],
      optionCLient: [],
      optionProject: [],
      OptionAccountEstimated: [
        {
          id: 1,
          text: "Kas Kecil Kantor Bantul",
          no: "111.000-0",
          type: "Kas & Bank",
        },

        {
          id: 2,
          text: "Kas Kecil Kantor Jogja",
          no: "111.000-0",
          type: "Kas & Bank",
        },
      ],
      placeholder: [
        {
          text: "kecamatan",
        },
      ],
      isEdit: this.$route.params.id ? true : false,
      labelPath: this.$route.params.label == "Piutang" ? "Piutang" : "Hutang",
      id_company: "",
      formModal: {
        key: "",
        id: "",
        nama: "",
        nilai: "",
        tipe: "credit",
      },
      id: this.$route.params.id ? this.$route.params.id : "",
      formData: {
        id: this.$route.params.id ? this.$route.params.id : "",
        nama: "",
        id_client: "",
        id_divisi: "",
        id_company: "",
        kode_project: "",
        beban_akun: "",
        nilai: "",
        jatuh_tempo: "",
        id_jurnal: "",
        status: "aktif",
        status_pembayaran: "unpaid",
        tipe: "credit",
      },
      isEditable: true,
      rules: {
        nama: {
          required: true,
        },
        id_client: {
          required: true,
        },
        beban_akun: {
          required: true,
        },
        nilai: {
          required: true,
        },
      },
      formError: [],
      AccountEstimated: "",
      onModal: false,
      formDetail: [],
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      isSubmit: false,
      invoiceList: [],
      showModalInvoice: false,
      NomorInvoice: "",
      listPembayaran: [],
      umur: null,
    };
  },

  methods: {
    buttonHide(data) {
      this.btnHide = data;
      console.log(this.btnHide);
    },
    editable() {
      this.validated = 2;
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.btn-lampiran {
  background-color: #f9ffd7;
  color: #b9b201;
  font-weight: 400;
}

#modalAddPayment .btn-save {
  background-color: transparent !important;
  color: #68caf8 !important;
  border: 1px solid #68caf8 !important;
  font-weight: 400;
  font-size: 15px;
  padding: 10px 0px;
}

#modalAddPayment .btn-save:hover {
  background-color: #68caf8 !important;
  color: #fff !important;
}

.card-detail-invoice {
  background: linear-gradient(103.2deg, #6ec0fe 0%, #4df3dd 100%);
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
  line-height: 30px;
}

#modalPrintInvoice .modal-dialog {
  max-width: 1162px;
}

.btn-add-payment {
  background-color: #d7ffea;
  color: #3cb778;
  padding: 5px 10px;
  font-weight: 500;
  margin-right: 15px;
}

.btn-print-invoice {
  background-color: #f9ffd7;
  color: #b9b201;
  padding: 5px 10px;
  font-weight: 500;
}

.address-company {
  font-size: 12px;
  color: #263238;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.noEstimated {
  font-size: 12px;
  color: #898989;
}

.form-label {
  max-width: 134px;
  width: 200px;
}

.card-total {
  display: flex;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.payment-invoice {
  padding: 0px 46px;
}

.sub-total {
  margin-right: 50px;
  font-size: 14px;
}

.amount {
  font-size: 14px;
}

.name-company {
  font-size: 18px;
  font-weight: 500;
}

.detail-company .value {
  color: #263238;
  font-size: 16px;
  font-weight: 400;
}

.detail-company .text {
  color: #cdcdcd;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.title-invoice {
  font-weight: 700;
  font-size: 50px;
  background: linear-gradient(102.18deg, #6ec0fe -17.12%, #61efe7 95.5%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.total-payment {
  font-size: 24px;
  font-weight: 600;
  margin-top: 10px;
}

.status-invoice-paid {
  background: #d7ffea;
  color: #3cb778;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 50px;
  border: none;
  cursor: default;
  border-radius: 5px;
}

.btn-export {
  background: #e4efff;
  color: #68caf8;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  margin-right: 15px;
}

.modal .btn-print-invoice {
  background: #f9ffd7;
  color: #b9b201;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 23px;
  border-radius: 5px;
  border: none;
}

.btn-invoice {
  background: #fff3c7;
  color: #f8ce3a;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
</style>
